<template>
  <div id="detailObject">
      <el-page-header @back="goBack" title="对象详情"></el-page-header>
      <el-form :model="formData">
          <el-row :gutter="20">
              <el-col :span="12">
                  <el-form-item label="对象名称" style="width: 100%">
                    <el-input v-model="formData.name" disabled  autocomplete="off"></el-input>
                  </el-form-item>
                   <el-form-item label="对象编码" style="width: 100%">
                    <el-input v-model="formData.code" disabled  autocomplete="off"></el-input>
                  </el-form-item>
              </el-col>
          </el-row>
          <el-row :gutter="20">
              <el-col :span="12">
                  <el-form-item label="应用编码" style="width: 100%">
                    <el-input v-model="formData.appCode" disabled  autocomplete="off"></el-input>
                  </el-form-item>
                   <el-form-item label="数据库表名" style="width: 100%">
                    <el-input v-model="formData.tableName" disabled  autocomplete="off"></el-input>
                  </el-form-item>
              </el-col>
          </el-row>
          <el-row :gutter="20">
              <el-col :span="12">
                  <el-form-item label="状态" style="width: 100%">
                    <el-select
                                size="small"
                                 style="width:100%"
                                v-model="formData.status"
                                placeholder="请选择"
                                disabled
                                ><el-option
                                    v-for="item in statusList"
                                    :key="item.value"
                                    :label="item.label"
                                    :value="item.value"
                                ></el-option
                      ></el-select>
                  </el-form-item>
                   <el-form-item label="父级对象" style="width: 100%">
                    <el-input v-model="formData.parentCode" disabled  autocomplete="off"></el-input>
                  </el-form-item>
              </el-col>
          </el-row>
          <el-row :gutter="20">
              <el-col :span="12">
                  <el-form-item label="微服务标识" style="width: 100%">
                    <el-input v-model="formData.microServiceId" disabled  autocomplete="off"></el-input>
                  </el-form-item>
                   <el-form-item label="对象目录" style="width: 100%">
                    <el-input v-model="formData.objCatalog" disabled  autocomplete="off"></el-input>
                  </el-form-item>
              </el-col>
          </el-row>
          <el-row :gutter="20">
              <el-col :span="12">
                  <el-form-item class="last" label="备注" style="width: 100%">
                    <el-input type="textarea" v-model="formData.note" disabled maxlength="255"></el-input>
                  </el-form-item>
              </el-col>
          </el-row>
      </el-form>
      <div style="display: flex;justify-content: space-around;align-items: center;width: 500px;margin-bottom:20px">
        <div class="item">对象属性</div>
        <div class="item">
           <el-button type="primary" size="medium"  @click="handleAdd('attr')">新增属性</el-button>
        </div>
        <div class="item">
           <el-button type="primary" size="medium"  @click="handleConTanent">关联租户</el-button>
        </div>
        <!-- <div class="item"></div> -->
      </div>
      <!-- <TableWrap> -->
        <el-table :data="attrTableData" :header-cell-style="{background:'#FAFAFA'}" >
            <el-table-column prop="id" label="主键标识"> </el-table-column>
            <el-table-column prop="name" label="属性名称"> </el-table-column>
            <el-table-column prop="code" label="属性编码"> </el-table-column>
            <el-table-column prop="isprimary" label="是否主键">
                <template v-slot="scope">
                    {{  scope.row.isprimary===0?'是':'否' }}
                </template>
            </el-table-column>
            <el-table-column prop="dataType" label="数据类型">
              <template v-slot="scope">
                                {{ $utils.getDicValue('OBJECT_OBJ_DATATYPE', scope.row.status).mean }}
                            </template>
            </el-table-column>
            <el-table-column prop="dataLength" label="数据长度"> </el-table-column>
            <el-table-column prop="isextend" label="是否扩展">
                <template v-slot="scope">
                    {{  scope.row.isextend===0?'是':'否' }}
                </template>
            </el-table-column>
            <el-table-column label="操作" width="210" align="center">
                <template v-slot="scope">
                    <el-button type="text" size="small" plain @click="handleEdit(scope.row.id, 'attr')">编辑</el-button>
                    <el-button type="text" size="small" plain @click="handleDetail(scope.row.id, 'attr')">详情</el-button>
                    <el-button type="text" size="small" plain @click="handleDelete(scope.row.id,'attr')">
                        <span style="color:#F5222D">
                            删除
                        </span>
                    </el-button>
                </template>
            </el-table-column>
        </el-table>
      <!-- </TableWrap> -->
       <div style="display: flex;justify-content: space-around;align-items: center;width: 335px;margin-bottom:20px">
        <div class="item">对象行为</div>
        <div class="item">
           <el-button type="primary" size="medium"  @click="handleAdd('action')">新增行为</el-button>
        </div>
      </div>
        <el-table :data="actionTableData" :header-cell-style="{background:'#FAFAFA'}" >
            <el-table-column prop="actionName" label="名称"> </el-table-column>
            <el-table-column prop="actionCode" label="编码"> </el-table-column>
            <el-table-column prop="actionMethod" label="方法"> </el-table-column>
            <el-table-column prop="actionScript" label="脚本"> </el-table-column>
            <el-table-column prop="actionOrder" label="顺序"></el-table-column>
            <el-table-column label="操作" width="210" align="center">
                <template v-slot="scope">
                    <el-button type="text" size="small" plain @click="handleEdit(scope.row.id, 'action')">编辑</el-button>
                    <el-button type="text" size="small" plain @click="handleDetail(scope.row.id, 'action')">详情</el-button>
                    <el-button type="text" size="small" plain @click="handleDelete(scope.row.id,'action')">
                        <span style="color:#F5222D">
                            删除
                        </span>
                    </el-button>
                </template>
            </el-table-column>
        </el-table>
        <el-dialog :title="dialogTitle" :visible.sync='dialogVisible' @close='cancel'>
          <el-form v-if="type==='attr'" ref="ruleForm" :rules="rules" :model="formAttr">
             <el-row :gutter="20">
               <el-col :span="12">
                   <el-form-item label="属性名称" style="width: 100%">
                    <el-input v-model="formAttr.name" :disabled="disbaled"  autocomplete="off"></el-input>
                   </el-form-item>
               </el-col>
               <el-col :span="12">
                   <el-form-item label="属性编码" style="width: 100%">
                    <el-input v-model="formAttr.code" :disabled="disbaled"  autocomplete="off"></el-input>
                   </el-form-item>
               </el-col>
             </el-row>
             <el-row :gutter="20">
               <el-col :span="12">
                   <el-form-item prop="nullable" label="是否允许为空" style="width: 100%">
                    <el-select
                                size="small"
                                 style="width:100%"
                                v-model="formAttr.nullable"
                                placeholder="请选择"
                                :disabled="disbaled"
                                ><el-option
                                    v-for="item in nullList"
                                    :key="item.value"
                                    :label="item.label"
                                    :value="item.value"
                                ></el-option
                      ></el-select>
                   </el-form-item>
               </el-col>
               <el-col :span="12">
                   <el-form-item label="字典" style="width: 100%">
                     <el-input v-model="dictionaryName" @focus="chooseDic" :disabled="disbaled" autocomplete="off"></el-input>
                   </el-form-item>
               </el-col>
             </el-row>
             <el-row :gutter="20">
               <el-col :span="12">
                   <el-form-item prop="isprimary" label="是否主键" style="width: 100%">
                    <el-select
                                size="small"
                                 style="width:100%"
                                v-model="formAttr.isprimary"
                                placeholder="请选择"
                                :disabled="disbaled"
                                ><el-option
                                    v-for="item in nullList"
                                    :key="item.value"
                                    :label="item.label"
                                    :value="item.value"
                                ></el-option
                      ></el-select>
                   </el-form-item>
               </el-col>
               <el-col :span="12">
                   <el-form-item prop="isextend" label="是否扩展属性" style="width: 100%">
                    <el-select
                                size="small"
                                 style="width:100%"
                                v-model="formAttr.isextend"
                                placeholder="请选择"
                                :disabled="disbaled"
                                ><el-option
                                    v-for="item in nullList"
                                    :key="item.value"
                                    :label="item.label"
                                    :value="item.value"
                                ></el-option
                      ></el-select>
                   </el-form-item>
               </el-col>
             </el-row>
             <el-row :gutter="20">
               <el-col :span="12">
                   <el-form-item prop="dataType" label="数据类型" style="width: 100%">
                   <el-select
                                size="small"
                                 style="width:100%"
                                v-model="formAttr.dataType"
                                placeholder="请选择"
                                ><el-option
                                    v-for="item in typeList"
                                    :key="item.val"
                                    :label="item.mean"
                                    :value="item.val"
                                ></el-option
                      ></el-select>
                   </el-form-item>
               </el-col>
               <el-col :span="12">
                   <el-form-item label="依赖对象" style="width: 100%">
                    <SelectTree  :props="props"
                    :options="list"
                    :value="valueId"
                    :clearable="isClearable"
                    :accordion="isAccordion"
                    @getValue="getValue($event)"
                    ></SelectTree>
                   </el-form-item>
               </el-col>
             </el-row>
             <el-row :gutter="20">
               <el-col :span="12">
                   <el-form-item label="数据长度" style="width: 100%">
                    <el-input v-model="formAttr.dataLength" :disabled="disbaled"  autocomplete="off"></el-input>
                   </el-form-item>
               </el-col>
               <el-col :span="12">
                   <el-form-item label="正则表达式" style="width: 100%">
                    <el-input v-model="formAttr.regExp" :disabled="disbaled"  autocomplete="off"></el-input>
                   </el-form-item>
               </el-col>
             </el-row>
             <el-row :gutter="20">
               <el-col :span="24">
                   <el-form-item label="备注" style="width: 100%">
                    <el-input type="textarea" :disabled="disbaled" v-model="formAttr.note"  autocomplete="off"></el-input>
                   </el-form-item>
               </el-col>
             </el-row>
          </el-form>
           <el-form v-else-if="type==='action'" :model="formAction">
             <el-row :gutter="20">
               <el-col :span="12">
                   <el-form-item label="行为名称" style="width: 100%">
                    <el-input v-model="formAction.actionName" :disabled="disbaled"  autocomplete="off"></el-input>
                   </el-form-item>
               </el-col>
               <el-col :span="12">
                   <el-form-item label="行为编码" style="width: 100%">
                    <el-input v-model="formAction.actionCode" :disabled="disbaled"  autocomplete="off"></el-input>
                   </el-form-item>
               </el-col>
             </el-row>
             <el-row :gutter="20">
               <el-col :span="12">
                   <el-form-item label="顺序" style="width: 100%">
                    <el-input v-model="formAction.actionOrder" :disabled="disbaled" autocomplete="off"></el-input>
                   </el-form-item>
               </el-col>
             </el-row>
             <el-row :gutter="20">
               <el-col :span="24">
                   <el-form-item label="方法" style="width: 100%">
                    <el-input type="textarea" :disabled="disbaled" v-model="formAction.actionMethod"  autocomplete="off"></el-input>
                   </el-form-item>
               </el-col>
             </el-row>
             <el-row :gutter="20">
               <el-col :span="24">
                   <el-form-item label="脚本" style="width: 100%">
                    <el-input type="textarea" :disabled="disbaled" v-model="formAction.actionScript"  autocomplete="off"></el-input>
                   </el-form-item>
               </el-col>
             </el-row>
          </el-form>
          <el-form  v-else :model="formConnct">
            <el-row :gutter="20">
               <el-col :span="12">
                   <el-form-item label="租户名称" style="width: 100%">
                    <el-input v-model="formConnct.name"  autocomplete="off"></el-input>
                   </el-form-item>
               </el-col>
               <el-col :span="12">
                   <el-form-item label="对象编码" style="width: 100%">
                    <el-input v-model="formConnct.name"  autocomplete="off"></el-input>
                   </el-form-item>
               </el-col>
             </el-row>
             <el-row :gutter="20">
               <el-col :span="12">
                   <el-form-item label="扩展属性" style="width: 100%">
                    <el-input v-model="formConnct.name"  autocomplete="off"></el-input>
                   </el-form-item>
               </el-col>
               <el-col :span="12">
                   <el-form-item label="对象名称" style="width: 100%">
                    <el-input v-model="formConnct.name"  autocomplete="off"></el-input>
                   </el-form-item>
               </el-col>
             </el-row>
             <el-row :gutter="20">
               <el-col :span="24">
                   <el-form-item label="已关联扩展属性" style="width: 100%">
                    <el-input type="textarea" v-model="formConnct.name"  autocomplete="off"></el-input>
                   </el-form-item>
               </el-col>
             </el-row>
          </el-form>
          <div slot="footer" class="dialog-footer" style="text-align:center">
            <el-button @click="cancel">取 消</el-button>
            <el-button type="primary" @click="save">保 存</el-button>
          </div>
        </el-dialog>
        <dicDialog :dicDialgVisible="dicDialgVisible" @callback="handleDicDialog" @curRowCallback="getChoseDic"></dicDialog>
  </div>
</template>

<script>
import SelectTree from '../treeSelect.vue'
import dicDialog from './dicDialog.vue'
import { reqDetail, reqAttrList, reqActionList, reqAttrAdd, reqActionAdd, reqActionEdit, reqActionDetail, reqAttrDetail, reqActionDel, reqAttrDel, reqAttrEdit, reqObjectTree } from '@/api/metadataManagement/metadataManagement.js'
export default {
    name: 'ivyobjectD',
    components: {
        dicDialog,
        SelectTree
    },
    data () {
        return {
            dicDialgVisible: false,
            formData: {
                name: '',
                code: '',
                appCode: '',
                microServiceId: '',
                parentCode: '',
                tableName: '',
                note: '',
                objCatalog: '',
                status: ''
            },
            formAttr: {
                name: '',
                code: '',
                dictionaryCode: '',
                dataLength: '',
                regExp: '',
                note: '',
                objectCode: '',
                dataType: ''
            },
            rules: {
                dataType: [
                    { required: true, message: '请选择数据类型', trigger: 'change' },
                ],
                isprimary: [
                    { required: true, message: '请选择', trigger: 'change' },
                ],
                isextend: [
                    { required: true, message: '请选择', trigger: 'change' },
                ],
                nullable: [
                    { required: true, message: '请选择', trigger: 'change' },
                ],
            },
            dictionaryName: '',
            formAction: {
                actionName: '',
                actionCode: '',
                actionMethod: '',
                actionOrder: '',
                actionScript: '',
                objectCode: ''
            },
            formConnct: {
                name: ''
            },
            attrTableData: [],
            actionTableData: [],
            statusList: [
                { value: 1, label: '无效' },
                { value: 0, label: '有效' }
            ],
            nullList: [
                { value: 1, label: '否' },
                { value: 0, label: '是' }
            ],
            dialogTitle: '',
            dialogVisible: false,
            type: '',
            opType: '',
            disbaled: false,
            typeList: [],
            codeList: [],
            props: {
                // 配置项（必选）
                value: 'code',
                label: 'name',
                children: 'children'
                // disabled:true
            },
            list: [],
            isClearable: true, // 可清空（可选）
            isAccordion: true, // 可收起（可选）
            valueId: '', // 初始ID（可选
        }
    },
    created () {
        this.currentId = this.$route.query.id
        reqObjectTree({ lazy: false }).then((res) => {
            this.list = res.data
        })
        // this.getAttrTableData()
        // this.getActionTableData()
        this.$utils.getDicData('OBJECT_OBJ_DATATYPE').then((res) => {
            this.typeList = res
            this.getDetail(this.$route.query.id)
            // console.log(res)
        })
    },
    methods: {
        getDetail (id) {
            reqDetail(id).then((res) => {
                this.formData = res.data
                this.attrTableData = res.data.objectAttributes
                this.actionTableData = res.data.objectActions
                this.formAttr.objectCode = res.data.code
                this.formAction.objectCode = res.data.code
            })
        },
        getValue (value) {
            this.valueId = value
            // console.log(this.valueId)
            this.formAttr.dependencyObjectCode = value
        },
        chooseDic () {
            this.dicDialgVisible = true
        },
        getAttrTableData () {
            const { query, size, current } = this
            reqAttrList({ ...query, size, current }).then((res) => {
                const { data } = res
                this.attrTotal = data.total
                this.attrTableData = data.records
            })
        },
        getActionTableData () {
            const { query, size, current } = this
            reqActionList({ ...query, size, current }).then((res) => {
                const { data } = res
                this.actionTotal = data.total
                this.actionTableData = data.records
            })
        },
        goBack () {
            this.$router.go(-1)
        },
        handleAdd (item) {
            this.dialogVisible = true
            this.opType = 'add'
            this.disbaled = false
            if (item === 'attr') {
                this.dialogTitle = '新增属性'
                this.type = 'attr'
            } else {
                this.dialogTitle = '新增行为'
                this.type = 'action'
            }
        },
        handleDelete (id, op) {
            this.$confirm('删除后不可恢复，确定要删除吗？', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                if (op === 'attr') {
                    reqAttrDel(id).then((res) => {
                        if (res.code === 0) {
                            this.$message.success('删除成功')
                            this.getAttrTableData()
                        }
                    })
                } else {
                    reqActionDel(id).then((res) => {
                        if (res.code === 0) {
                            this.$message.success('删除成功')
                            this.getActionTableData()
                        }
                    })
                }
            })
        },
        cancel () {
            this.dialogVisible = false
            this.formAction = {}
            this.formAttr = {}
        },
        handleConTanent () {
            this.dialogTitle = '关联租户'
            this.dialogVisible = true
            this.type = 'connect'
        },
        handleEdit (id, t) {
            this.disbaled = false
            if (t === 'attr') {
                this.type = 'attr'
                this.dialogTitle = '编辑对象属性'
                reqAttrDetail(id).then((res) => {
                    // console.log(res.data)
                    this.formAttr = res.data
                    this.dictionaryName = res.data.dictionaryName
                })
            } else {
                this.type = 'action'
                this.dialogTitle = '编辑对象行为'
                reqActionDetail(id).then((res) => {
                    this.formAction = res.data
                })
            }
            this.dialogVisible = true
            this.opType = 'edit'
        },
        handleDetail (id, t) {
            this.disbaled = true
            if (t === 'attr') {
                this.type = 'attr'
                this.dialogTitle = '对象属性详情'
                reqAttrDetail(id).then((res) => {
                    this.formAttr = res.data
                    this.dictionaryName = res.data.dictionaryName
                })
            } else {
                this.type = 'action'
                this.dialogTitle = '对象行为详情'
                reqActionDetail(id).then((res) => {
                    this.formAction = res.data
                })
            }
            this.dialogVisible = true
            this.disbaled = true
        },
        save () {
            if (this.opType === 'add') {
                if (this.type === 'attr') {
                    this.$refs.ruleForm.validate((valid) => {
                        if (valid) {
                            this.formAttr.dataLength = Number(this.formAttr.dataLength)
                            // console.log(this.formAttr)
                            reqAttrAdd(this.formAttr).then((res) => {
                                if (res.code === 0) {
                                    this.$message.success('新增属性成功')
                                    this.getDetail(this.currentId)
                                }
                            })
                            this.dialogVisible = false
                        }
                    })
                } else {
                    this.formAction.actionOrder = Number(this.formAction.actionOrder)
                    reqActionAdd(this.formAction).then((res) => {
                        if (res.code === 0) {
                            this.$message.success('新增行为成功')
                            this.getDetail(this.currentId)
                        }
                    })
                    this.dialogVisible = false
                }
            } else {
                if (this.type === 'attr') {
                    this.formAttr.dataLength = Number(this.formAttr.dataLength)
                    reqAttrEdit(this.formAttr).then((res) => {
                        if (res.code === 0) {
                            this.$message.success('修改属性成功')
                            this.getDetail(this.currentId)
                        }
                        this.dialogVisible = false
                    })
                } else {
                    this.formAction.actionOrder = Number(this.formAction.actionOrder)
                    reqActionEdit(this.formAction).then((res) => {
                        if (res.code === 0) {
                            this.$message.success('修改属性成功')
                            this.getDetail(this.currentId)
                        }
                    })
                    this.dialogVisible = false
                }
            }
            this.formAction = {}
            this.formAttr = {}
        },
        handleDicDialog (item) {
            this.dicDialgVisible = item
        },
        getChoseDic (item) {
            this.formAttr.dictionaryCode = item.code
            this.dictionaryName = item.name
        }
    }
}
</script>

<style lang="scss">
#detailObject{
  .el-col-12{
    display: flex;
  }
  .el-form-item{
    flex: 1;
    display: flex;
  }
  .el-form-item .el-form-item__label{
    width: 102px !important;
  }
  .last .el-form-item__label{
    width: 92px !important;
  }
  .el-form-item__content{
    width: 90%;
  }
  .item{
    flex: 1;
    text-align: center;
  }
 .el-dialog .el-form-item .el-form-item__label{
    width: 131px !important;
  }
 .el-col-24 .el-form-item .el-form-item__label{
      width: 114px !important;
  }
.el-textarea .el-textarea__inner{ // 然后找到对应的类名，在这里将拉伸去掉即可
  resize: none;
}
}
</style>
