import request from '@/api/request'

// export function reqDictionary (params) { return request({ url: '/api/dictionary/dic/', params, method: 'post' }) }

/* 字典列表 */
export function reqDictionary (params) { return request({ url: '/dictionary/page', params }) }

/* 字典新增 */
export function reqDictionaryAdd (params) { return request({ url: '/dictionary', method: 'post', params }) }

/* 字典修改 */
export function reqDictionaryUpdate (params) { return request({ url: '/dictionary', method: 'put', params }) }

/* 通过主键id查询字典 */
export function reqDictionaryGetById (id) { return request({ url: '/dictionary/:id', params: id }) }

/* 通过主键id删除字典 */
export function reqDictionaryDelById (id) { return request({ url: '/dictionary/:id', method: 'delete', params: id }) }
