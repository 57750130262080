<template>
  <div id="dicDialog">
    <el-dialog title="选择字典"  @close="handleCancel" :visible.sync="dialogVisible">
         <TableWrap  @callback="handlePagination" :total="total">
                     <template slot="tablesearch">
                        <SearchWrap :searchOptions="searchOptions" inline @callback="handleSearchCallback"></SearchWrap>
                    </template>
                    <el-table
                        :data="tableData"
                        v-loading="loading"
                        :header-cell-style="{background:'#FAFAFA'}"
                    >
                      <el-table-column width="50">
                        <template v-slot="scope">
                            <el-radio :label="scope.$index" v-model="radio" @change.native="getCurrentRow(scope.row)"></el-radio>
                        </template>
                    </el-table-column>
                        <el-table-column
                            label="状态"
                            prop="status"
                            width="60"
                        >
                            <template v-slot="scope">
                                {{ $utils.getDicValue('SYSTEM_NORMAL_STATUS', scope.row.status).mean }}
                            </template>
                        </el-table-column>
                          <el-table-column
                            label="应用"
                            prop="appCode"
                            width="140"
                        >
                        </el-table-column>
                        <el-table-column
                            label="字典编码"
                            prop="code"
                        ></el-table-column>
                        <el-table-column
                            label="字典名称"
                            prop="name"
                        ></el-table-column>
                        <el-table-column
                            label="创建时间"
                            prop="createTime"
                        ></el-table-column>
                        <el-table-column
                            label="修改时间"
                            prop="updateTime"
                        ></el-table-column>
                    </el-table>
                </TableWrap>
                <div style="text-align: right;">
                <el-button type="primary" size="middle" @click="handleConfirm">
                    确认
                </el-button>
                <el-button type="primary" size="middle" @click="handleCancel" plain>
                    取消
                </el-button>
            </div>
    </el-dialog>
  </div>
</template>

<script>
import { reqDictionary } from '@/api/system/dictionary'
import { reqStytemList } from '@/api/question/safty.js'
import tableMixin from '@/mixIn/tableMixIn'
export default {
    name: 'dicDialog',
    mixins: [tableMixin],
    props: {
        dicDialgVisible: {
            type: Boolean,
            default: false
        }
    },
    watch: {
        dicDialgVisible: {
            handler (newVal) {
                this.dialogVisible = newVal
            }
        }
    },
    data () {
        return {
            searchOptions: [
                { type: 'input', key: 'name', label: '字典名称' },
                { type: 'select', key: 'appCode', label: '应用名称', options: [], clearable: true }
            ],
            reqTableData: reqDictionary,
            dialogVisible: false,
            radio: '',
            curRowData: {},
            SystemOptions: []
        }
    },
    created () {
        this.getTableData()
        reqStytemList().then((res) => {
            // console.log(res)
            res.data.records.forEach(item => {
                const obj = {}
                obj.value = item.code
                obj.label = item.name
                this.SystemOptions.push(obj)
            })
            this.searchOptions[1].options = this.SystemOptions
        })
    },
    methods: {
        // 传递回调
        handleCallback () {
            this.$emit('callback', this.dialogVisible)
        },
        // 获取当前行数据
        getCurrentRow (row) {
            console.log(row)
            this.curRowData = row
        },
        // 关闭对话框
        handleCancel () {
            this.dialogVisible = false
            this.handleCallback()
        },
        handleConfirm () {
            this.dialogVisible = false
            this.handleCallback()
            if (this.curRowData.id) {
                this.$emit('curRowCallback', this.curRowData)
                this.handleCancel()
            } else {
                this.$message.warning('请选择字典！')
                this.dialogVisible = true
            }
        }
    }
}
</script>

<style lang="scss">
#dicDialog{
  .cus_search_wrap{
    width: 100%;
  }
  .el-form {
    display: flex;
  }
     .el-radio__label {
   display: none;
 }
}
</style>
