import request from '@/api/request'

export function reqList (params) { return request({ url: '/object/page', params: params }) }

export function reqObjectTree (params) { return request({ url: '/object/tree', params: params }) }

export function reqEdit (params) { return request({ url: '/object', params: params, method: 'PUT' }) }

export function reqAdd (params) { return request({ url: '/object', params: params, method: 'POST' }) }

export function reqDel (id) { return request({ url: '/object/:id', params: { id }, method: 'delete' }) }

export function reqDetail (id) { return request({ url: '/object/:id', params: { id } }) }

// 对象行为管理
export function reqActionList (params) { return request({ url: '/objectaction/page', params: params }) }

export function reqActionEdit (params) { return request({ url: '/objectaction', params: params, method: 'PUT' }) }

export function reqActionDel (id) { return request({ url: '/objectaction/:id', params: { id }, method: 'delete' }) }

export function reqActionAdd (params) { return request({ url: '/objectaction', params: params, method: 'POST' }) }

export function reqActionDetail (id) { return request({ url: '/objectaction/:id', params: { id } }) }

// 对象属性
export function reqAttrList (params) { return request({ url: '/objectattribute/page', params: params }) }

export function reqAttrEdit (params) { return request({ url: '/objectattribute', params: params, method: 'PUT' }) }

export function reqAttrDel (id) { return request({ url: '/objectattribute/:id', params: { id }, method: 'delete' }) }

export function reqAttrAdd (params) { return request({ url: '/objectattribute', params: params, method: 'POST' }) }

export function reqAttrDetail (id) { return request({ url: '/objectattribute/:id', params: { id } }) }
